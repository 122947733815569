@import "../../../variables.scss";

header {
	height: $--header-height--;

	.header-content {
		position: fixed;
		top: 0;
		width: 100%;
		left: 0;
		background: $--red-color--;
		height: $--header-height--;
		z-index: 20;
		transition: .5s top;

		&.hidden {
			top: -$--header-height--;
		}
	}

	.container {
		display: grid;
		height: $--header-height--;
		grid-template-columns: 1fr 4.5rem 1fr;
	}

	.menu {
		position: relative;
		height: $--header-height--;
		
		img {
			@include absolute-left;
		}
	}

	.logo {
		height: $--header-height--;
		position: relative;
		z-index: 5;

		path {
			transition: .2s all;
			transition-delay: 0.2s;
		}

		svg {
			width: 4.5rem;
			@include absolute-center;
		}
	}

	.sign-in {
		text-align: right;
		line-height: $--header-height--;
		height: $--header-height--;
		
		a {
			font-family: $--bold-font--;
			color: #FFF;
			font-size: 2rem;
			text-transform: uppercase;
			text-decoration: none;
		}
	}

	&.toggled {
		.logo path {
			fill: $--red-color--;
		}

		nav {
			left: 0;
		}
	}
}


nav {
	height: 100%;
	position: fixed;
	top: 0;
	left: -100%;
	height: 100%;
	width: 100%;
	padding-right: 3rem;
	box-sizing: border-box;
	transition: .5s all;

	.nav-content {
		background: #000;
		width: 100%;
		height: 100%;
		position: relative;

		.close-btn {
			position: absolute;
			top: 2.8rem;
			left: 3rem;
		}

		> ul {
			margin-left: 3rem;
			padding-top: 12rem;

			li {
				margin-bottom: 5.5rem;
				
				a {
					font-family: $--bold-font--;
					font-size: 40px;
					font-weight: 700;
					line-height: 40px;
					text-transform: uppercase;
					color: #FFF;
					text-decoration: none;
				}
			}
		}
		
		.footer {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
		}
	}
}

.menu .logout-link {
	display: none;
}

@media all and (min-width: 768px) {
	.menu img {
		display: none;
	}

	.menu .logout-link {
		display: block;
		font-family: $--bold-font--;
		font-size: 2rem;
		font-weight: 700;
		line-height: 8rem;
		text-transform: uppercase;
		color: #FFF;
		text-decoration: none;
	}
}