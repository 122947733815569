@import-normalize;

@font-face {
	font-family: 'DINNextLTPro-BoldCondensed';
	src: url('./fonts/DINNextLTPro-BoldCondensed.eot?#iefix') format('embedded-opentype'),  url('./fonts/DINNextLTPro-BoldCondensed.otf')  format('opentype'),
			url('./fonts/DINNextLTPro-BoldCondensed.woff') format('woff'), url('./fonts/DINNextLTPro-BoldCondensed.ttf')  format('truetype'), url('./fonts/DINNextLTPro-BoldCondensed.svg#DINNextLTPro-BoldCondensed') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'DINNextLTPro-LightCondensed';
	src: url('./fonts/DINNextLTPro-LightCondensed.eot?#iefix') format('embedded-opentype'),  url('./fonts/DINNextLTPro-LightCondensed.otf')  format('opentype'),
			url('./fonts/DINNextLTPro-LightCondensed.woff') format('woff'), url('./fonts/DINNextLTPro-LightCondensed.ttf')  format('truetype'), url('./fonts/DINNextLTPro-LightCondensed.svg#DINNextLTPro-LightCondensed') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'LatypeCondensed';
	src: url('./fonts/LatypeCondensed.eot?#iefix') format('embedded-opentype'),  url('./fonts/LatypeCondensed.woff') format('woff'), url('./fonts/LatypeCondensed.ttf')  format('truetype'), url('./fonts/LatypeCondensed.svg#LatypeCondensed') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'DINNextLTPro-MediumCond';
	src: url('./fonts/DINNextLTPro-MediumCond.eot?#iefix') format('embedded-opentype'),  url('./fonts/DINNextLTPro-MediumCond.otf')  format('opentype'),
		   url('./fonts/DINNextLTPro-MediumCond.woff') format('woff'), url('./fonts/DINNextLTPro-MediumCond.ttf')  format('truetype'), url('./fonts/DINNextLTPro-MediumCond.svg#DINNextLTPro-MediumCond') format('svg');
	font-weight: normal;
	font-style: normal;
}