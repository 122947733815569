$--red-color--: #cf2130;
$--bright-red-color--: #d11224;
$--green-color--: #6fbe7f;

$--header-height--: 8rem;

$--condensed-font--: "DINNextLTPro-LightCondensed";
$--bold-font--: "DINNextLTPro-BoldCondensed";
$--light-font--: "LatypeCondensed";
$--medium-font--: "DINNextLTPro-MediumCond";

@mixin absolute-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@mixin absolute-left {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(0, -50%);
}