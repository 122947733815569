@import "../../../variables.scss";

.account-edit {
	padding-top: 2rem;

	.profile-span {
		text-align: center;
		color: #575757;
		font-family: $--medium-font--;
		font-size: 1.4rem;
		margin-top: 1.5rem;
	}

	input[name=imageUploader] {
		display: none
	}
	.user-image {
		height: 16rem;
		width: 16rem;
		border-radius: 16rem;
		margin: 0 auto;
		background-size: cover;
		background-position: center center;
	}

	.edit-link {
		color: $--green-color--;
		font-family: $--medium-font--;
		font-size: 2.2rem;
		font-weight: 500;
		text-decoration: underline;
		appearance: none;
		border: 0;
		background: transparent;
		margin: 1rem auto 0 auto;
		display: table;
	}

	.custom-form-form {
		margin-top: 5rem;

		form {
			padding-top: 2rem;
		}
	}
}