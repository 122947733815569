@import "../../variables.scss";

.homepage-introduction {
	margin-bottom: 9rem;

	.lg-title {
		margin-bottom: 1rem;
	}
}

.timeline-container {
	position: relative;
}

.homepage-all-todo .timeline-1 {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 2;
	height: 0px;
	overflow: hidden;

	.timeline-item {
		.regular-image {
			display: none !important;
		}

		.white-image {
			display: inline-block !important;
		}
	}

	.lg-title,
	.lg-p {
		color: #FFF !important;
	}

	.timeline-bar .start-bullet,
	.timeline-bar .line {
		background: #fff !important;
	}

	.timeline-bar .bullet {
		border: 2px solid #FFF !important;
		background: #FFF;
	}
}

.homepage-more-sharing {
	margin-top: 12rem;
	text-align: center;
	margin-bottom: 12rem;

	img {
		margin-bottom: 2rem;
	}

	.lg-title {
		margin-bottom: 1rem;
	}
}

.homepage-all-todo {
	background: $--bright-red-color--;
	padding-top: 4.5rem;

	> .lg-title {
		padding-bottom: 8rem;
	}

	.timeline {
		display: grid;
		grid-template-columns: 4rem 1fr;
		grid-column-gap: 15px;
		padding-top: 4.5rem;

		.timeline-item {
			margin-bottom: 8rem;
			text-align: center;

			.regular-image {
				display: inline-block;
			}
		
			.white-image {
				display: none;
			}

			img {
				margin-bottom: 2.5rem;
			}
		}

		.timeline-bar {
			position: relative;

			.timeline-bar-container {
				@include absolute-center;
			}

			.start-bullet {
				height: 2rem;
				width: 2rem;
				background: #6a0a13;
				border-radius: 3rem;
				margin: 0 auto;
			}

			.line {
				width: .2rem;
				height: 37rem;
				background: #6a0a13;
				margin: 5px auto 5px auto;
			}

			.bullet {
				height: 1rem;
				width: 1rem;
				border-radius: 1rem;
				border: 2px solid #6a0a13;
				margin: 0 auto;
			}
		}

		.lg-title,
		.lg-p {
			color: #6a0a13;
		}
	}

	.button {
		padding-bottom: 6rem;
	}
	
	.btn {
		margin: 0 auto;
	}
}

.homepage-upcoming-events {
	padding-top: 7rem;
	padding-bottom: 8.5rem;

	&.no-pad {
		padding-top: 0;
		margin-top: -3rem;

		.container {
			.lg-subtitle.left,
			.lg-title.left {
				text-align: center;
			}
		}
	}
	
	.events-slideshow {
		padding-top: 2rem;
		max-width: 1200px;
		margin: 0 auto;
		width: 100%;
	}

	.carousel .control-dots .dot {
		box-shadow: none;
		background: #777;
	}

	.carousel .control-dots .dot.selected {
		background: $--red-color--;
	}

	.carousel .slide {
		background: transparent;
		padding: 0 30px;
	}

	.event {
		padding-bottom: 5rem;
		
		img {
			width: 100%;
			margin-bottom: 1rem;
		}

		.lg-title {
			margin-bottom: 1rem;
		}

		.btn {
			margin-top: 2rem;
		}
	}
}

.desktop-events {
	display: none;
}

@media all and (min-width: 768px) {
	.homepage-all-todo .timeline {
		grid-template-columns: 1fr;

		.timeline-bar {
			display: none;
		}

		.timeline-items {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-column-gap: 40px;

			img {
				height: 130px;
			}
		}
	}

	.homepage-upcoming-events > .container >.lg-subtitle.left,
	.homepage-upcoming-events > .container >.lg-title.left {
		text-align: center;
	}

	.carousel {
		display: none;
	}

	.desktop-events {
		display: block;

		.container {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-row-gap: 60px;
			grid-column-gap: 40px;

			.event .lg-title {
				text-align: left !important;
				font-size: 4rem;
			}
		}
	}
}
