@import "./../../variables.scss";

* {
	padding: 0;
	margin: 0;
}

html {
  	font-size: 62.5%;
}

body {
	background-image: url("/imgs/background.png");
	background-repeat: repeat;
}

.container {
	width: 100%;
	max-width: 960px;
	margin: 0 auto;
	padding: 0 30px;
	box-sizing: border-box;
}

.react-confirm-alert-body {
	font-family: $--light-font-- !important;
	font-size: 3rem;
}

.react-confirm-alert-body h1 {
	font-family: $--bold-font-- !important;
	font-size: 3.2rem;
}

.lg-title,
.bullet-list > p em {
	font-family: $--bold-font--;
	color: $--bright-red-color--;
	font-size: 5rem;
	line-height: 4.3rem;
	text-transform: uppercase;
	text-align: center;
	font-weight: 400;
	font-style: initial;
	background-image: linear-gradient(225deg, #ea294a 0%, $--bright-red-color-- 100%);
	background-clip: text;
	-webkit-background-clip: text;

	&.white {
		background: none;
		color: #FFF;
	}

	&.left {
		text-align: left;
	}
}

.lg-p {
	font-family: $--condensed-font--;
	font-size: 2.5rem;
	line-height: 3.2rem;
	font-weight: 400;
	text-align: center;

	a {
		color: $--green-color--;
		font-family: $--medium-font--;
		font-weight: 500;
		text-decoration: underline;
	}

	span {
		font-family: $--bold-font--;
	}

	&.white {
		color: #FFF;
	}

	&.left {
		text-align: left;
	}
}

.lg-subtitle,
.bullet-list > p > strong {
	font-family: $--light-font--;
	font-size: 8rem;
	line-height: 6.2rem;
	color: #000;
	text-transform: uppercase;
	text-align: center;
	font-weight: 400;

	&.white {
		background: none;
		color: #FFF;
	}

	&.left {
		text-align: left;
	}
}

.alert {
	background: $--bright-red-color--;
	border-radius: 5px;
	padding: 20px;
	text-align: center;
	font-family: $--bold-font--;
	color: #FFF;
	font-size: 2rem;
	margin-bottom: 4rem;

	&.alert-success {
		background: $--green-color--;
	}
}

.react-confirm-alert-body > h1 {
	color: $--bright-red-color--;
}

.react-confirm-alert-button-group button:nth-child(1) {
	background: $--bright-red-color--;
}

.react-confirm-alert-button-group button:nth-child(2) {
	background: transparent;
	color: $--bright-red-color--;
}

#react-confirm-alert .react-confirm-alert-button-group > button,
.btn {
	border: 0;
	background: transparent;
	background-image: url("/svg/red-button.svg");
	max-width: 27.5rem;
	width: 100%;
	height: 6.7rem;
	display: block;
	text-align: center;
	color: #ffffff;
	font-family: $--bold-font--;
	font-size: 2.2rem;
	font-weight: 700;
	text-transform: uppercase;
	line-height: 7.2rem;
	text-decoration: none;
	background-size: 100% 100%;
	outline: 0;
	padding: 0;

	&:disabled {
		opacity: .4;
	}

	&.white-btn {
		background-image: url('/svg/white-button.svg');
		color: $--bright-red-color--;
	}

	&.btn-outline {
		background-image: url("/svg/outline.svg");
		color: $--bright-red-color--;
	}

	&.blue-btn {
		background-image: url('/svg/blue-button.svg');
		color: #FFF;
	}

	&.full {
		max-width: 100%;
	}
}


#react-confirm-alert .react-confirm-alert-button-group > button:nth-child(2) {
	background: transparent;
	color: $--bright-red-color--;
}

.custom-form {
	padding-top: 1rem;

	.react-datepicker-wrapper {
		width: 100%;
	}
	
	.datePicker-wrapper,
	.react-datepicker {
		font-size: 1.1rem;
		font-family: $--condensed-font--;
	}

	.react-datepicker__header {
		background: $--bright-red-color--;
		color: #FFF;
	}

	.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
		color: #FFF;
		font-size: 1.5rem;
	}

	.react-datepicker__day-name {
		color: #FFF;
		font-size: 1.4rem;
	}

	.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
		border-bottom-color: $--bright-red-color--;
	}

	.react-datepicker__navigation--next {
		border-left-color: #FFF;
	}

	.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
		width: 2.5rem;
		line-height: 2.5rem;
	}

	.react-datepicker__day, .react-datepicker__time-name {
		font-size: 1.3rem;
	}

	.react-datepicker__day--outside-month {
		opacity: .3;
	}
	.react-datepicker__navigation--previous {
		border-right-color: #FFF;
	}

	.react-datepicker-popper{
		z-index: 11115;
	}

	.custom-intro {
		padding-bottom: 6rem;

		.lg-p {
			padding-top: 1rem;
		}
	}

	.invalid-feedback {
		font-family: $--condensed-font--;
		font-size: 1.5rem;
		color: $--bright-red-color--;
		margin-top: .5rem;
	}

	.custom-form-form {
		.blue-btn {
			margin-bottom: 1.5rem;
		}

		form {
			padding: 8rem 0;
		}

		.file-upload {
			padding: 3rem;
			text-align: center;
			border: 1px dashed $--bright-red-color--;
			background-size: cover;
			background-position: center;

			&.preview {
				img,
				p {
					opacity: 0;
				}
			}

			p {
				font-family: $--condensed-font--;
				font-size: 2.5rem;
				font-weight: 300;
				max-width: 15rem;
				margin: 1.5rem auto 0 auto;
			}
		}

		p.notice {
			font-family: $--condensed-font--;
			font-size: 1.8rem;
			font-weight: 300;
			line-height: 2.2rem;
			margin-top: 1rem;
		}

		input[type=file] {
			visibility: hidden;
		}

		.checkbox {
			margin-bottom: 2rem;

			label {
				font-family: $--condensed-font--;
				font-size: 2rem;
				font-weight: 300;
				line-height: 2.2rem;

				a {
					color: #000;
				}

				span {
					font-family: $--bold-font--;
				}
			}
		}
	}
}

.styled-checkbox {
	position: absolute; // take it out of document flow
	opacity: 0; // hide it
  
	& + label {
		position: relative;
		cursor: pointer;
		padding: 0;
	}
  
	// Box.
	& + label:before {
		content: '';
		margin-right: 10px;
		display: inline-block;
		vertical-align: text-top;
		width: 20px;
		height: 20px;
		background: white;
		border: 2px solid $--bright-red-color--;
		margin-top: -3px;
		border-radius: 3px;
	}
  
	// Box focus
	&:focus + label:before {
	  	box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
	}
  
	// Box checked
	&:checked + label:before {
	  	background:$--bright-red-color--;
	}
	
	// Disabled state label.
	&:disabled + label {
		color: #b8b8b8;
		cursor: auto;
	}
  
	// Disabled box.
	&:disabled + label:before {
		box-shadow: none;
		background: #ddd;
	}
  
	// Checkmark. Could be replaced with an image
	&:checked + label:after {
		margin-top: -3px;
		content: '';
		position: absolute;
		left: 6px;
		top: 11px;
		background: white;
		width: 2px;
		height: 2px;
		box-shadow: 
		2px 0 0 white,
		4px 0 0 white,
		4px -2px 0 white,
		4px -4px 0 white,
		4px -6px 0 white,
		4px -8px 0 white;
		transform: rotate(45deg);
	}
}

.birthday_group {
	display: grid;
	grid-template-columns: 2fr 1fr 1fr;
	grid-column-gap: 40px;
}

.form-group {
	position:relative;  
	margin-bottom: 5rem;
}
  
.form-label {
	position: absolute;
	left: 0;
	top: 10px;
	color: #999;
	z-index: 10;
	transition: transform 150ms ease-out, font-size 150ms ease-out;
	color: #969696;
	font-family: $--condensed-font--;
	font-size: 2.5rem;
	font-weight: 300;
}

.form-group option.disabled {
	color: #969696;
	font-family: $--condensed-font--;
	font-size: 2.5rem;
	font-weight: 300;
}

.focused .form-label {
	transform: translateY(-125%);
	font-size: 1.8rem;
	color: #000;

	img {
		display: none;
	}
}
  
.form-input {
	position: relative;
	padding: 12px 0px 5px 0;
	appearance: none;
	width: 100%;
	outline: 0;
	border: 0;
	background: transparent;
	font-family: $--condensed-font--;
	font-size: 2.5rem;
	font-weight: 500;
	color: #000;
	border-bottom: 1px solid #969696;
}

.form-group p.note {
	font-family: $--condensed-font--;
	font-size: 2.2rem;
	font-weight: 300;
	margin-top: 1.5rem;
	text-align: right;

	a {
		color: $--green-color--;
		font-family: $--bold-font--;
		font-weight: 500;
		text-decoration: underline;
	}
}