@import "../../variables.scss";

.login-form {
	.custom-intro {
		padding-top: 4rem;
		padding-bottom: 1rem;
	}
	
	.custom-form form {
		padding-bottom: 2.5rem;
	}
	
	.sign-up {
		font-family: $--condensed-font--;
		font-size: 2.2rem;
		font-weight: 300;
		text-align: center;
		margin-top: 4rem;

		a {
			color: $--green-color--;
			font-family: $--bold-font--;
			font-weight: 500;
			text-decoration: underline;
		}
	}
}