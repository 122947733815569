@import "../../variables.scss";

.event-page {
	padding: 3rem 0;

	.bullet-list > p > strong {
		line-height: 3.2rem;
	}
	
	.event-image {
		margin: 3rem 0;
		width: 100%;

		&.desktop-image {
			display: none;
		}
	}

	.bullet-list {
		margin-bottom: 5rem;

		ul {
			list-style-position: outside;
			color: #000;
			padding-left: 1rem;
			list-style: none;
			margin-bottom: 5rem;

			li::before {
				content: "\2022";
				color: $--bright-red-color--;
				font-weight: bold;
				display: inline-block;
				width: 1rem;
				margin-left: -1rem;
				padding-right: 1rem;
			}
		}
		
		li {
			font-family: $--condensed-font--;
			font-size: 2.5rem;
			font-weight: 300;
			line-height: 4.3rem;
		}
	}

	.homepage-packages {
		margin-bottom: 5rem;
	}
}

@media all and (min-width: 768px) {
	.event-page .event-image {
		&.mobile-image {
			display: none;
		}

		&.desktop-image {
			display: block;
		}
	}
}