@import "../../variables.scss";

.profile {
	.container {
		padding-bottom: 6rem;
	}
	
	.profile-image {
		height: 28rem;
		background-size: cover;
		background-position: center center;
	}

	.qr {
		display: table;
		margin: -5rem auto 0 auto;

		&.block {
			background: $--bright-red-color--;
			min-height: 20.7rem;
			width: 20.7rem;
			text-align: center;
			padding: 2rem;
			box-sizing: border-box;
			display: table;

			.qr-text {
				display: table-cell;
				vertical-align: middle;
			}

			h2 {
				font-family: $--bold-font--;
				font-size: 3rem;
				font-weight: 700;
				line-height: 2.5rem;
				text-transform: uppercase;
				color: #FFF;
				margin-bottom: 1rem;
			}

			p {
				color: #ffffff;
				font-family: $--medium-font--;
				font-size: 2.2rem;
				font-weight: 500;
			}
		}

		img {
			display: block;
		}
	}

	.lg-p.notice {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	.lg-p.email {
		margin-bottom: 3rem;
	}

	.profile-row {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 1.5rem;

		p:nth-child(1) {
			text-align: right;
			font-family: $--condensed-font--;
			font-size: 2.5rem;
			font-weight: 300;
			line-height: 3.2rem;
		}
		
		p.red {
			font-family: $--bold-font--;
			font-size: 2.5rem;
			font-weight: 500;
			line-height: 3.2rem;
			color: $--bright-red-color--;
		}
	}

	.profile-info {
		margin-bottom: 4rem;
	}

	.btn.full {
		margin-bottom: 2rem;
	}
}
