@import "../../variables.scss";

.packages {
	.package {
		margin-top: 5rem;
		border: 1px solid $--bright-red-color--;
		background: #FFF;
		padding: 4.5rem 2.5rem 6rem 2.5rem;
		position: relative;

		.package-image {
			text-align: center;
		}

		.package-title {
			text-align: center;
			margin-top: 1rem;

			h2 {
				font-family: $--light-font--;
				font-size: 6rem;
				text-transform: uppercase;
				font-weight: 400;
				color: #000;
			}

			h3 {
				font-weight: 400;

				.superscript {
					font-family: $--bold-font--;
					color: $--bright-red-color--;
					font-size: 1.3rem;
					vertical-align: top;
				}

				.price {
					background-image: linear-gradient(225deg, #ea294a 0%, $--bright-red-color-- 100%);
					background-clip: text;
					-webkit-background-clip: text;
					color: $--bright-red-color--;
					font-family: $--bold-font--;
					font-size: 4rem;
					font-weight: 700;
					text-transform: uppercase;
				}

				.year {
					font-family: $--condensed-font--;
					font-size: 2.5rem;
					font-weight: 300;
					line-height: 3.2rem;
					vertical-align: super;
					padding-left: .5rem;
				}
			}
		}

		.package-info {
			margin-top: 4rem;

			ul {
				font-family: $--condensed-font--;
				font-size: 2.5rem;
				font-weight: 300;
				line-height: 3.6rem;
				list-style-position: outside;
				color: #000;
				padding-left: 1rem;
				list-style: none;

				li::before {
					content: "\2022";
					color: $--bright-red-color--;
					font-weight: bold;
					display: inline-block;
					width: 1rem;
					margin-left: -1rem;
					padding-right: 1rem;
				}
			}
		}

		.package-button {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, 50%);
			width: 100%;

			.btn {
				margin: 0 auto;
			}
		}
	}
}