@import "../../../variables.scss";

.footer {
	height: 6.5rem;
	background: #000;

	.container {
		display: grid;
		grid-template-columns: 1fr 1fr;

		p a {
			color: #FFF;
			text-decoration: none;
			font-family: $--condensed-font--;
			font-size: 1.8rem;
			font-weight: 300;
			text-transform: uppercase;
			margin-left: 20px;
		}

		p span {
			display: inline-block;
			margin-left: 20px;
		}

		p {
			font-family: $--condensed-font--;
			font-size: 1.8rem;
			font-weight: 300;
			color: #FFF;
			line-height: 6.5rem;
		}

		p a, 
		p span {
			display: none;
		}

		ul {
			text-align: right;
			height: 6.5rem;

			li {
				height: 6.5rem;
				line-height: 6.5rem;
				display: inline-block;
				margin-left: .5rem;
			}

			img {
				vertical-align: middle;
			}


			a {
				height: 6.5rem;
				line-height: 6.5rem;
				display: inline-block;
			}
		}
	}
}

@media all and (min-width: 768px) {
	.footer .container p span,
	.footer .container p a {
		displaY: inline-block;
	}
}